<template>
  <section id="surgery-data">
    <div class="header">
      <h4 class="title mb-0">Dados da cirurgia</h4>
      <span v-b-modal.appointment-modal>Visualizar agendamento</span>
    </div>
    <hr />
    <b-container class="procedures" fluid>
      <b-row v-for="procedure in surgery.procedures" :key="procedure.id" class="procedure-container mb-3">
        <section class="procedure-header mb-2">
          <div class="d-flex justify-content-between align-items-center">
            <p class="mb-1">Procedimento</p>
            <Timer 
              v-if="!loadingTimer"
              :time="procedure.duration" 
              @timer="time => updateProcedureTime(procedure, time)" 
              :startClock="!!procedure.time_running"
              @paused="paused => handleClockStatus(procedure, paused)"
              :canEdit="surgery.status !== 'Finalizado'"
            />
          </div>
          <h5>{{ procedure?.item?.name }}</h5>
        </section>
        
        <b-row class="info">
          <b-col>
            <span>Lateralidade</span>
            <p>{{ procedure.laterality ?? '-' }}</p>
          </b-col>
          <b-col>
            <span>Médico responsável</span>
            <p>{{ procedure.participants.find(p => p.type === 'Executante')?.beneficiary?.name }}</p>
          </b-col>
          <b-col>
            <span>Instrumentador(a)</span>
            <p>{{ procedure.participants.find(p => p.type === 'Instrumentador(a)')?.beneficiary?.name }}</p>
          </b-col>
          <b-col>
            <span>Anestesista</span>
            <p>{{ procedure.participants.find(p => p.type === 'Anestesista')?.beneficiary?.name }}</p>
          </b-col>
        </b-row>
        <b-row class="info">
          <b-col>
            <span>Observações</span>
            <p>{{ procedure.observations ?? '-' }}</p>
          </b-col>
        </b-row>
      </b-row>
    </b-container>

    <AppointmentModal :appointmentId="surgery?.appointment_id"/>
  </section>
</template>

<script>
export default {
  name: 'SurgeryData',  

  components: {
    AppointmentModal: () => import('@/components/AppointmentModal'),
    Timer: () => import('@/components/General/Timer')
  },
  props: {
    surgery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingTimer: true,
    }
  },
  async created() {
    this.loadingTimer = true
    await this.handleProceduresTimer()
    this.loadingTimer = false
  },
  methods: {
    async handleProceduresTimer () {
      await this.surgery.procedures.map(async procedure => {
        if (procedure.time_running) {
          const endDate = this.moment(procedure.end_datetime).utcOffset(-3)
          const now = this.moment().utcOffset(0)

          const newDuration = this.moment(now.diff(endDate)).utcOffset(3)
          if (procedure.duration) {
            newDuration.add({
                hours: this.moment(procedure.duration).utcOffset(-3).hours(),
                minutes: this.moment(procedure.duration).utcOffset(0).minutes(),
                seconds: this.moment(procedure.duration).utcOffset(0).seconds(),
            })
              
          }
            
          await this.updateProcedureTime(procedure, newDuration)
        }
      })
    },
    async handleClockStatus (surgeryProcedure, paused) {
      try {
        const payload = {
          time_running: !paused,
        }

        this.$set(surgeryProcedure, 'time_running', !paused)
        if (!surgeryProcedure.start_datetime) {
          this.$set(surgeryProcedure, 'start_datetime', this.moment())
          payload.start_datetime = surgeryProcedure.start_datetime
        }

        this.$set(surgeryProcedure, 'end_datetime', this.moment())
        payload.end_datetime = surgeryProcedure.end_datetime
        
        await this.api.updateSurgeryInformationProcedure(surgeryProcedure.id, payload)
      } catch (err) {
        this.$toast.error('Não foi possível atualizar o tempo do procedimento, contate o suporte se o erro persistir.')
      }
    },
    async updateProcedureTime (surgeryProcedure, time) {
      try {
        const res = await this.api.updateSurgeryInformationProcedure(surgeryProcedure.id, {
          duration: time.format('HH:mm:ss'),
          end_datetime: this.moment(),
        })
        this.$set(surgeryProcedure, 'duration', res.data.duration)
        this.$set(surgeryProcedure, 'end_datetime', res.data.end_datetime)
      } catch (err) {
        console.log('err', err)
        this.$toast.error('Não foi possível atualizar o tempo do procedimento, contate o suporte se o erro persistir.')
      }
    }
  },
}
</script>

<style lang="scss">
#surgery-data {
  background-color: var(--neutral-000);
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;

  h4 { font-size: 1.1rem; }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: var(--blue-500);
      font-weight: 600;
      cursor: pointer;
    }
  }

  .procedures {
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .procedure-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid  var(--neutral-200) !important;

    .info {
      span {
        color: var(--dark-blue);
        font-weight: 600;
      }

      div {
        height: 70px;
      }
    }
  }

  .procedure-header {
    p {
      font-weight: 600;
      font-size: 1rem;
      color: var(--dark-blue);
    }

    h5 {
      font-size: 1.1rem;
      font-weight: 600;
      color: var(--neutral-700);
    }
  }
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>